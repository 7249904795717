@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add new selectors here if you need to control
whether body can be scrolled or not. */
body:has(
    div[id='landing-header-drawer'][data-open='true'],
    div[id='main-header-drawer'][data-open='true']
  ) {
  overflow-y: hidden !important;
}

@layer base {
  .gradient-light {
    background: var(--gradientLight);
  }

  .scrollbar-hidden {
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .card {
    @apply rounded-2.5xl bg-white shadow-obe-neutral-xxs;

    &.card-small {
      @apply px-4 py-4 lg:px-16 lg:py-10;
    }
  }
}
